<template>
  <v-theme-provider light>
    <base-section
      id="spacer"
      class="accent pt-6 pb-6 mb-0"
    />
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',
    provide: {
      heading: { align: 'center' },
    },
  }
</script>

<style>
.spacing{
  letter-spacing: 1em !important
}
</style>
